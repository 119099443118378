import * as React from 'react';
import { Link } from 'gatsby';

import * as styles from './PostPreview.module.css';

export default function PostPreview({ path, post }) {
  const { slug, title } = post;

  return (
    <div className={styles.container}>
      <Link to={`${path}/${slug}/`}>
        <h2>{title}</h2>
      </Link>
    </div>
  );
}
