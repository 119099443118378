import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import Pagination from 'components/Pagination';
import { PostPreview } from 'components/Post';

import useSiteMetadata from 'hooks/useSiteMetadata';

import * as styles from './blog.module.css';

export const query = graphql`
  query BlogQuery($slug: String!, $postIds: [String!]) {
    contentfulNews(slug: { eq: $slug }) {
      id
      slug
      title
    }

    allContentfulPost(filter: { id: { in: $postIds } }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

const BlogPage = ({ data, location, pageContext }) => {
  const { siteUrl } = useSiteMetadata();

  const news = get(data, `contentfulNews`, {});
  const posts = get(data, 'allContentfulPost.edges', []);

  const canonical = `${siteUrl}${location.pathname}`;

  const pageInfo = {
    pageCount: pageContext.numPages,
    hasPreviousPage: pageContext.currentPage !== 1,
    hasNextPage: pageContext.currentPage < pageContext.numPages,
    currentPage: pageContext.currentPage,
  };

  const { postIds } = pageContext;
  const sortByPostIds = (a, b) =>
    postIds.indexOf(a.node.id) - postIds.indexOf(b.node.id);
  posts.sort(sortByPostIds);

  return (
    <Layout location={location}>
      <GatsbySeo
        title="Archive"
        canonical={canonical}
        openGraph={{
          title: 'Archive',
          url: canonical,
        }}
      />

      <h1 className={styles.title}>Archive</h1>

      {posts.map((post) => (
        <PostPreview
          key={post.node.id}
          post={post.node}
          path={`/${news.slug}/archive`}
        />
      ))}

      <Pagination path={`/${news.slug}/archive`} pageInfo={pageInfo} />
    </Layout>
  );
};

export default BlogPage;
