import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

import * as styles from './Pagination.module.css';

export default function Pagination({ pageInfo, path = '/' }) {
  const { pageCount, hasPreviousPage, hasNextPage, currentPage } = pageInfo;

  const prevPage =
    currentPage - 1 === 1 ? '' : `${(currentPage - 1).toString()}/`;
  const nextPage = (currentPage + 1).toString();

  const computedPath = path === '/' ? '' : path;

  if (pageCount === 1) return null;

  return (
    <div className={styles.container}>
      <Link
        disabled={!hasPreviousPage}
        to={`${computedPath}/${prevPage}`}
        rel="prev"
      >
        ←
      </Link>

      <div className={styles.currentPage}>
        <FormattedMessage
          id="general.pagination.current_page"
          values={{ current: currentPage, total: pageCount }}
        />
      </div>

      <Link
        disabled={!hasNextPage}
        to={`${computedPath}/${nextPage}/`}
        rel="next"
      >
        →
      </Link>
    </div>
  );
}
